<template>
  <div>
    <HeaderForm :payload="payload">                                                                                                                                                              
      <p class="text-primary mb-0 h6">Se completo exitosamente</p>
    </HeaderForm>
    <section>
      <p style="font-size: 1.2rem">
        Solicitud No.: <strong>{{ formData.numero }}</strong>
      </p>
    </section>
    <section v-if="formData.esMenor">
      <div class="formCovid__title my-1">
        <h2 class="formCovid__title--container">
          Datos Personales del Solicitante
        </h2>
      </div>
      <b-row class="mt-2">
        <b-col cols="12" md="4">
          <p class="p-label mb-0">Documento de Identidad</p>
          <p style="word-break: break-all">
            {{ objectIdent[formData.solicitanteIdentificacionTipo].value }} -
            {{ formData.solicitanteIdentificacionNumero }}
          </p>
        </b-col>
        <b-col cols="12" md="4">
          <p class="p-label mb-0">Nombre y Apellido</p>
          <p style="word-break: break-all">
            {{ formData.solicitanteNombres }}
            {{ formData.solicitanteApellidos }}
          </p>
        </b-col>
        <b-col cols="12" md="4">
          <p class="p-label mb-0">Sexo</p>
          <p>{{ objectSex[formData.solicitanteSexo].value }}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="4">
          <p class="p-label mb-0">Teléfono Móvil</p>
          <p>{{ formData.solicitanteTelefono }}</p>
        </b-col>
        <b-col cols="4">
          <p class="p-label mb-0">Correo Electrónico</p>
          <p style="word-break: break-all">{{ formData.solicitanteEmail }}</p>
        </b-col>
      </b-row>
   
   
    </section>
   
    <section>
      <div class="formCovid__title my-1">
        <h2 class="formCovid__title--container">
          Datos Personales {{ formData.esMenor ? "del Paciente" : "" }}
        </h2>
      </div>
      <b-row>
        <b-col cols="12" md="4">
          <p class="p-label mb-0">Documento de Identidad</p>
          <p style="word-break: break-all">
            {{ objectIdent[formData.identificacionTipo].value }} -
            {{ formData.identificacionNumero }}
          </p>
        </b-col>

        <b-col cols="12" md="4">
          <p class="p-label mb-0">Nacionalidad</p>
          <p style="word-break: break-all">
            {{ formData.nacionalidad }}
          </p>
        </b-col>

        <b-col cols="12" md="4">
          <p class="p-label mb-0">Nombre y Apellido</p>
          <p style="word-break: break-all">
            {{ formData.nombres }}
            {{ formData.apellidos }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="4">
          <p class="p-label mb-0">Fecha de Nacimiento</p>
          <p style="word-break: break-all">
            {{ formData.fechaNacimiento }}
          </p>
        </b-col>
        <b-col cols="12" md="4">
          <p class="p-label mb-0">Sexo</p>
          <p style="word-break: break-all">
            {{ objectSex[formData.sexo].value }}
          </p>
        </b-col>
        <b-col cols="12" md="4">
          <p class="p-label mb-0">Teléfono Móvil</p>
          <p style="word-break: break-all">
            {{ formData.telefonoMovil }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="4">
          <p class="p-label mb-0">Correo Electrónico</p>
          <p style="word-break: break-all">
            {{ formData.email }}
          </p>
        </b-col>
      </b-row>
    </section>

    <section v-if="formData.sucursal">
      <div class="formCovid__title my-2">
        <h2 class="formCovid__title--container">Sucursal</h2>
      </div>
      <b-row>
        <b-col cols="12">
          <template v-if="formData.sucursal">
            <BranchofficeBox
              class="my-3"
              :branchoffice="formData.sucursal"
              :showName="true"
            />
          </template>
        </b-col>
      </b-row>
    </section>

    <section>
      <div class="formCovid__title my-2">
        <h2 class="formCovid__title--container">Pruebas a Realizarse</h2>
      </div>
      <b-row>
        <b-col cols="12">
          <ul class="pl-2">
            <li v-for="x in formData.pruebas" :key="x.pruebaid">
              <span style="fs-3"
                >{{ x.nombre }} (RD${{ x.precio | currency }})</span
              >
            </li>
          </ul>
          <p style="font-size: 1.1rem" class="amount">
            Monto total de pruebas a realizarse: RD${{
              formData.monto | currency
            }}
          </p>
        </b-col>
      </b-row>
    </section>
    <section class="text-center mt-2">
      <p class="mt-2 h6 text-bold font-weight-bolder">
        MUCHAS GRACIAS POR PREFERIRNOS!
      </p>
    </section>
    <section class="mt-4">
      <b-button @click="clearForm" variant="primary" class="w-100">
        Crear una nueva solicitud
      </b-button>
    </section>
  </div>
</template>
<script>
import { OBJ_SEXOS, OBJ_TIPO_IDENTIFICACION } from "@/utils/constants";
import moment from "moment";
import { BRow, BCol, BButton } from "bootstrap-vue";
import HeaderForm from "./HeaderForm.vue"
import BranchofficeBox from "./BranchofficeBox.vue";

export default {
  props: {
    infoRegister: {
      type: Object,
    },
    payload:{
      type:Object
    }
  },
  components: {
    BranchofficeBox,
    BRow,
    BCol,
    BButton,
    HeaderForm
  },
  data() {
    return {
      checkConfirmation: false,
      moment: moment,
      formData: {
        id: "",
        esEmbarazada: true,
        esPaciente: true,
        esMenor: false,
        modalidad: 0,
        nacionalidad: "",
        solicitanteIdentificacionTipo: "C",
        solicitanteIdentificacionNumero: "",
        solicitanteNombres: "",
        solicitanteApellidos: "",
        solicitanteTelefono: "",
        solicitanteEmail: "",
        solicitanteSexo: "M",
        parentesco: "",
        identificacionTipo: "C",
        identificacionNumero: "",
        nombres: "",
        apellidos: "",
        fechaNacimiento: "",
        sexo: "M",
        telefonoResidencia: "",
        telefonoMovil: "",
        telefonoOficina: "",
        email: "",
        pruebas: [
          {
            pruebaId: "",
            nombre: "",
            precio: 0,
          },
        ],
        numero: 0,
        sucursalNombre: "",
        monto: 0,
      },

      objectSex: OBJ_SEXOS,
      objectIdent: OBJ_TIPO_IDENTIFICACION,
    };
  },
  created() {
    this.formData = this.infoRegister;
  },
  methods: {
    clearForm() {
      this.$emit("handleCleanForm");
    },
  },
};
</script>
<style scoped>
.p-label {
  font-size: 1rem;
  font-weight: bold;
}
</style>
