<template>
  <div>
    <div class="containerInfoSucursal">
      <p class="m-0  mb-1" v-if="showName">
        <strong>Nombre:</strong>
        {{ branchoffice.nombre }}
      </p>
      <p class="m-0 mb-1">
        <strong>Dirección:</strong>
        {{ branchoffice.direccion }}
      </p>
      <p>
        <strong>Horarios:</strong>
        <span
          :style="`color: ${branchoffice.abierta == 1 ? '#00833d' : '#ea5455'}`"
        >
          {{ branchoffice.abierta == 1 ? "Abierto" : "Cerrada" }}</span
        >
        ⋅ <span> {{ branchoffice.horarios }}</span>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    branchoffice: {
      type: Object,
    },
    showName: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
<style scoped>
.containerInfoSucursal {
  font-size: 1rem;
  padding: 12px;
  border-radius: 5px;
  border: 1px dashed #29af8a;
  -webkit-box-shadow: 0px 0px 2px 1px rgba(148, 145, 148, 0.3);
  -moz-box-shadow: 0px 0px 2px 1px rgba(148, 145, 148, 0.3);
  box-shadow: 0px 0px 2px 1px rgba(148, 145, 148, 0.3);
}
</style>
