function validateIdentityCard(identityCard) {
    var replaceScore = identityCard.replace(/-/g, "");
  
    if (/^[0-9]{11}$/.test(replaceScore)) {
      var identityCardSub = replaceScore.substring(0, replaceScore.length - 1);
      var checkerNumber = replaceScore.substring(
        replaceScore.length - 1,
        replaceScore.length
      );
      var sum = 0;
      var identityCardValid = false;
  
      for (var i = 0; i < identityCardSub.length; i++) {
        var moduleDiv = 0;
  
        if (i % 2 == 0) moduleDiv = 1;
        else moduleDiv = 2;
  
        var result = Number(identityCardSub.substring(i, i + 1)) * moduleDiv;
  
        if (result > 9) {
          var resultString = result.toString();
  
          var one = resultString.substring(0, 1);
  
          var two = resultString.substring(1);
  
          result = Number(one) + Number(two);
        }
  
        sum += result;
      }
  
      var numberValidate = (10 - (sum % 10)) % 10;
      if (
        numberValidate == Number(checkerNumber) &&
        identityCardSub.substring(0, 3) != "000"
      ) {
        identityCardValid = true;
      } else {
        identityCardValid = false;
      }
  
      return identityCardValid;
    } else {
      return false;
    }
  }
  
  export default validateIdentityCard;