var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"formCovid"},[_c('div',{staticClass:"d-flex"},[_c('b-form',{staticClass:"formCovid w-100"},[_c('div',{staticClass:"text-center mb-3"},[_c('p',{staticClass:"mb-1"},[_c('strong',[_vm._v("¿Es menor de edad?")])]),_c('b-form-radio-group',{staticClass:"d-flex justify-content-center",attrs:{"name":"esMenor"},model:{value:(_vm.formData.esMenor),callback:function ($$v) {_vm.$set(_vm.formData, "esMenor", $$v)},expression:"formData.esMenor"}},[_c('b-form-radio',{staticClass:"radio--age",attrs:{"value":true}},[_vm._v("Si")]),_c('b-form-radio',{staticClass:"radio--age",attrs:{"value":false}},[_vm._v("No")])],1)],1),(_vm.formData.esMenor)?[_c('div',{staticClass:"formCovid__title my-1"},[_c('h2',{staticClass:"formCovid__title--container"},[_vm._v(" Datos Personales del Solicitante ")])]),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Tipo de Documento *"}},[_c('validation-provider',{attrs:{"immediate":"","rules":"required","name":"Tipo de documento del solicitante","vid":"solicitanteIdentificacionTipo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"label":"value","options":_vm.listTypeIdentity,"reduce":function (option) { return option.key; }},model:{value:(_vm.formData.solicitanteIdentificacionTipo),callback:function ($$v) {_vm.$set(_vm.formData, "solicitanteIdentificacionTipo", $$v)},expression:"formData.solicitanteIdentificacionTipo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3735684040)})],1)],1),_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Documento *"}},[_c('validation-provider',{attrs:{"immediate":"","vid":"solicitanteIdentificacionNumero","rules":("required|notSayPassport|" + (_vm.formData.solicitanteIdentificacionTipo == 'C'
                      ? 'document'
                      : '')),"name":"El documento del solicitante"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Documento"},model:{value:(_vm.formData.solicitanteIdentificacionNumero),callback:function ($$v) {_vm.$set(_vm.formData, "solicitanteIdentificacionNumero", $$v)},expression:"formData.solicitanteIdentificacionNumero"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"py-2 rbtn-group",attrs:{"disabled":_vm.formData.solicitanteIdentificacionTipo != 'C' &&
                          !_vm.loadingButtom,"variant":"primary"},on:{"click":function($event){return _vm.getInfoPadron(
                            _vm.formData.esMenor,
                            _vm.formData.solicitanteIdentificacionTipo,
                            _vm.formData.solicitanteIdentificacionNumero
                          )}}},[(_vm.loadingButtom)?_c('b-spinner',{staticClass:"mr-2",attrs:{"small":""}}):_vm._e(),_c('span',[_vm._v("Buscar")])],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1557253428)})],1)],1),_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"immediate":"","rules":"required","vid":"solicitanteNombres","name":"El nombre del solicitante"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Nombre *"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Nombre"},on:{"input":function($event){_vm.formData.solicitanteNombres = $event.toUpperCase()}},model:{value:(_vm.formData.solicitanteNombres),callback:function ($$v) {_vm.$set(_vm.formData, "solicitanteNombres", $$v)},expression:"formData.solicitanteNombres"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,4047541374)})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"immediate":"","rules":"required","vid":"solicitanteApellidos","name":"El apellido del solicitante"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Apellido *"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Apellido"},on:{"input":function($event){_vm.formData.solicitanteApellidos = $event.toUpperCase()}},model:{value:(_vm.formData.solicitanteApellidos),callback:function ($$v) {_vm.$set(_vm.formData, "solicitanteApellidos", $$v)},expression:"formData.solicitanteApellidos"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3880461182)})],1),_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"d-flex w-100"},[_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Sexo *"}},[_c('validation-provider',{attrs:{"immediate":"","rules":"required","vid":"solicitanteSexo","name":"El sexo del solicitante"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"label":"value","options":_vm.listSex,"reduce":function (option) { return option.key; }},model:{value:(_vm.formData.solicitanteSexo),callback:function ($$v) {_vm.$set(_vm.formData, "solicitanteSexo", $$v)},expression:"formData.solicitanteSexo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3276601462)})],1)],1)]),_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"immediate":"","rules":"required|numeric","vid":"solicitanteTelefono","name":"El teléfono móvil del solicitante"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Teléfono Móvil *"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Teléfono Móvil"},model:{value:(_vm.formData.solicitanteTelefono),callback:function ($$v) {_vm.$set(_vm.formData, "solicitanteTelefono", $$v)},expression:"formData.solicitanteTelefono"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1208023576)})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"immediate":"","rules":"required|email","name":"El correo electrónico del solicitante","vid":"solicitanteEmail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Correo Electrónico *"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Correo electrónico"},model:{value:(_vm.formData.solicitanteEmail),callback:function ($$v) {_vm.$set(_vm.formData, "solicitanteEmail", $$v)},expression:"formData.solicitanteEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2842241476)})],1)],1)]:_vm._e(),_c('div',{staticClass:"formCovid__title my-2"},[_c('h2',{staticClass:"formCovid__title--container"},[_vm._v(" Datos Personales "+_vm._s(_vm.formData.esMenor ? "del Paciente" : "")+" ")])]),_c('b-row',{staticClass:"mt-3"},[(!_vm.formData.esMenor)?[_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Tipo de Documento *"}},[_c('validation-provider',{attrs:{"immediate":"","rules":"required","name":"El tipo de documento","vid":"identificacionTipo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"label":"value","options":_vm.listTypeIdentity,"reduce":function (option) { return option.key; }},model:{value:(_vm.formData.identificacionTipo),callback:function ($$v) {_vm.$set(_vm.formData, "identificacionTipo", $$v)},expression:"formData.identificacionTipo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,50689777)})],1)],1),_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Documento *"}},[_c('validation-provider',{attrs:{"immediate":"","rules":("required|notSayPassport|" + (_vm.formData.identificacionTipo == 'C' ? 'document' : '') + "|documentMax:19"),"vid":"identificacionNumero","name":"El documento"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Documento"},model:{value:(_vm.formData.identificacionNumero),callback:function ($$v) {_vm.$set(_vm.formData, "identificacionNumero", $$v)},expression:"formData.identificacionNumero"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"py-2 rbtn-group",attrs:{"disabled":_vm.formData.identificacionTipo != 'C' && !_vm.loadingButtom,"variant":"primary"},on:{"click":function($event){return _vm.getInfoPadron(
                            _vm.formData.esMenor,
                            _vm.formData.identificacionTipo,
                            _vm.formData.identificacionNumero
                          )}}},[(_vm.loadingButtom)?_c('b-spinner',{staticClass:"mr-4 pr-2",attrs:{"small":""}}):_vm._e(),_c('span',[_vm._v("Buscar")])],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3092195621)})],1)],1)]:[_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"d-flex w-100"},[_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Parentesco"}},[_c('validation-provider',{attrs:{"immediate":"","rules":"required","vid":"parentesco","name":"El parentesco"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"label":"value","options":_vm.listRelantionship},model:{value:(_vm.formData.parentesco),callback:function ($$v) {_vm.$set(_vm.formData, "parentesco", $$v)},expression:"formData.parentesco"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Pasaporte"}},[_c('b-form-input',{attrs:{"placeholder":"Pasaporte"},model:{value:(_vm.formData.identificacionNumero),callback:function ($$v) {_vm.$set(_vm.formData, "identificacionNumero", $$v)},expression:"formData.identificacionNumero"}})],1)],1)],_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Nacionalidad *"}},[_c('validation-provider',{attrs:{"immediate":"","rules":"required","vid":"nacionalidad","name":"La nacionalidad"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"label":"value","options":_vm.listNationality},model:{value:(_vm.formData.nacionalidad),callback:function ($$v) {_vm.$set(_vm.formData, "nacionalidad", $$v)},expression:"formData.nacionalidad"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],2),_c('b-row',[_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"immediate":"","rules":"required","vid":"nombres","name":"El nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Nombre *"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"El nombre"},on:{"input":function($event){_vm.formData.nombres = $event.toUpperCase()}},model:{value:(_vm.formData.nombres),callback:function ($$v) {_vm.$set(_vm.formData, "nombres", $$v)},expression:"formData.nombres"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"immediate":"","rules":"required","vid":"apellidos","name":"El apellido"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Apellido *"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Apellido"},on:{"input":function($event){_vm.formData.apellidos = $event.toUpperCase()}},model:{value:(_vm.formData.apellidos),callback:function ($$v) {_vm.$set(_vm.formData, "apellidos", $$v)},expression:"formData.apellidos"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"immediate":"","rules":("required|" + (_vm.formData.esMenor ? 'isMenor' : 'isAdult')),"vid":"fechaNacimiento","name":"La fecha de nacimiento"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Fecha de Nacimiento *"}},[_c('flat-pickr',{staticClass:"form-control flat-picker bg-transparent",attrs:{"config":_vm.configBirthday,"placeholder":"YYYY-MM-DD"},model:{value:(_vm.formData.fechaNacimiento),callback:function ($$v) {_vm.$set(_vm.formData, "fechaNacimiento", $$v)},expression:"formData.fechaNacimiento"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"d-flex w-100"},[_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Sexo *"}},[_c('validation-provider',{attrs:{"immediate":"","rules":"required","vid":"sexo","name":"El sexo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"label":"value","options":_vm.listSex,"reduce":function (option) { return option.key; }},model:{value:(_vm.formData.sexo),callback:function ($$v) {_vm.$set(_vm.formData, "sexo", $$v)},expression:"formData.sexo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"immediate":"","rules":"required|numeric","vid":"telefonoMovil","name":"El teléfono móvil"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Teléfono Móvil *"}},[_c('b-form-input',{attrs:{"placeholder":"Teléfono Móvil","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.telefonoMovil),callback:function ($$v) {_vm.$set(_vm.formData, "telefonoMovil", $$v)},expression:"formData.telefonoMovil"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"immediate":"","rules":"required|email","name":"El correo electrónico","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Correo Electrónico *"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Correo electrónico"},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),(_vm.listBranchoffice.length > 0)?_c('div',{staticClass:"formCovid__title mb-1 mt-3"},[_c('h2',{staticClass:"formCovid__title--container"},[_vm._v("Sucursal")])]):_vm._e(),_c('b-row',[(_vm.listBranchoffice.length > 0)?[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"w-100 my-3",attrs:{"label":"Seleccionar sucursal *"}},[_c('validation-provider',{attrs:{"immediate":"","rules":"required","name":"La sucursal es requerida","vid":"sucursalId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"label":"nombre","options":_vm.listBranchoffice},model:{value:(_vm.formData.sucursal),callback:function ($$v) {_vm.$set(_vm.formData, "sucursal", $$v)},expression:"formData.sucursal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,347987799)})],1),(_vm.formData.sucursal)?[_c('BranchofficeBox',{staticClass:"mb-3",attrs:{"branchoffice":_vm.formData.sucursal,"showName":false}})]:_vm._e()],2)]:_vm._e()],2),_c('div',{staticClass:"formCovid__title mb-1 mt-3"},[_c('h2',{staticClass:"formCovid__title--container"},[_vm._v("Pruebas a Realizarse")])]),_c('b-row',{staticClass:"mt-3"},[_vm._l((_vm.listTest),function(x){return [_c('b-col',{key:x.id,staticClass:"mb-4",attrs:{"cols":"12"}},[_c('b-form-checkbox',{attrs:{"value":x},model:{value:(_vm.formData.pruebas),callback:function ($$v) {_vm.$set(_vm.formData, "pruebas", $$v)},expression:"formData.pruebas"}},[_c('span',{staticStyle:{"font-size":"1.1rem"}},[_vm._v(_vm._s(x.nombre)+" (RD$"+_vm._s(_vm._f("currency")(x.precio))+")")])])],1)]}),_c('b-col',[_c('p',{staticClass:"amount",staticStyle:{"font-size":"1.1rem"}},[_vm._v(" Monto total de pruebas a realizarse: RD$"+_vm._s(_vm._f("currency")(_vm.amountTest))+" ")]),(_vm.amountTest <= 0)?_c('small',{staticClass:"text-danger"},[_vm._v("Debe de seleccionar al menos una prueba")]):_vm._e()])],2),_c('div',{staticClass:"formCovid__title my-2"},[_c('h2',{staticClass:"formCovid__title--container"},[_vm._v("Datos de Pagos")])]),_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('CreditCard',{attrs:{"amount":_vm.amountTest},on:{"handleData":_vm.getDataCreditCard}})],1)],1),_c('b-row',{staticClass:"mt-4"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"w-100 py-2",attrs:{"variant":"primary"},on:{"click":_vm.sendData}},[_c('strong',{staticStyle:{"font-size":"1.05rem"}},[_vm._v(" Confirmar")])])],1)],1)],2)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }