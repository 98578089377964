<template>
  <div class="bg-img" style="min-height: 100vh">
    <div class="d-flex justify-content-center">
      <b-container class="containerParent" v-if="!registered">
        <div
          v-if="branchOffice.autoServicioDisponible"
          class="containerForm my-5"
        >
          <HeaderForm :payload="payload" />
          <section class="my-3">
            <Form
              :dataOrder="payload"
              @handleData="handleData"
              :clean="cleanFormDate"
            />
          </section>
        </div>
        <div v-else class="d-flex justify-content-center">
          <div class="p-2">
            <MessageNoExist
              :message="`${branchOffice.nombre} no esta disponible para auto servicio`"
            />
          </div>
        </div>
      </b-container>
      <template v-else>
        <b-container class="containerParent">
          <div class="containerForm my-3">
            <OrderComplete
              :infoRegister="infoRegister"
              @handleCleanForm="clearForm"
              :payload="payload"
            />
          </div>
        </b-container>
      </template>
    </div>

    <template>
      <b-modal v-model="showConfirmForm" size="lg" centered :hide-footer="true">
        <div>
          <ConfirmData
            :dataComplete="dataComplete"
            :dataHeader="payload"
            @handleSubmitData="handleRegister"
            @handleCancel="showConfirmForm = false"
          />
        </div>
      </b-modal>
    </template>
  </div>
</template>
<script>
import MessageNoExist from "./components/MessageNoExist.vue";
import ConfirmData from "./components/ConfirmData.vue";
import Form from "./components/Form.vue";
import { BContainer, BModal } from "bootstrap-vue";
import moment from "moment";
import OrderComplete from "./components/OrderCompleted.vue";
import HeaderForm from "./components/HeaderForm.vue";

export default {
  components: {
    BModal,
    Form,
    BContainer,
    MessageNoExist,
    ConfirmData,
    OrderComplete,
    HeaderForm,
  },
  data() {
    return {
      showConfirmForm: false,
      registered: false,
      infoRegister: {},
      confirmData: false,
      dataComplete: {},
      moment: moment,
      cleanFormDate: new Date(),
    };
  },

  methods: {
    handleRegister(data) {
      this.infoRegister = data;
      this.showConfirmForm = false;
      this.registered = true;
    },
    handleData(data) {
      this.dataComplete = data;
      this.showConfirmForm = true;
      window.scrollTo(0, 0);
    },
    clearForm() {
      this.cleanFormDate = new Date();
      this.registered = false;
    },
    isJsonString(value) {
      try {
        let data = JSON.parse(Buffer.from(value, "base64"));
        return data;
      } catch {
        return null;
      }
    },
  },

  computed: {
    payload() {
      let queries = Object.keys(this.$route.query);
      if (queries.includes("payload")) {
        return this.$route.query.payload == null ||
          this.$route.query.payload == ""
          ? null
          : this.isJsonString(this.$route.query.payload);
      } else if (queries.includes("suc")) {
        return this.$route.query.suc == null || this.$route.query.suc == ""
          ? null
          : { s: { id: this.$route.query.suc } };
      } else {
        return null;
      }
    },
    branchOffice() {
      return this.$store.state.branchoffice;
    },
  },
};
</script>
<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.containerForm {
  &--title2 {
    font-size: 1.15rem;
  }
  > header {
    color: #6e6b7b;
  }
}

.containerConfirm {
  border-top: 4.5px solid #2ab089;
  max-width: 1000px;
  background: #fff;
  border-radius: 7px;
  -webkit-box-shadow: 0px 0px 5px 1px rgba(148, 145, 148, 0.5);
  -moz-box-shadow: 0px 0px 5px 1px rgba(148, 145, 148, 0.5);
  box-shadow: 0px 0px 5px 1px rgba(148, 145, 148, 0.5);

  &__data {
    padding: 21px 15px;
    @media only screen and (min-width: 450px) {
      padding: 21px 25px;
    }
  }
  @media only screen and (min-width: 450px) {
    width: 80%;
    max-width: 700px;
  }
}

.containerParent {
  max-width: 1200px;
  font-size: 0.9em !important;
  @media only screen and (max-width: 500px) {
    padding: 8px 11px;
  }
}

@media only screen and (max-width: 382px) {
  .containerParent {
    padding: 0px;
  }
  .containerForm {
    padding: 8px 14px;
  }
}

.containerForm {
  @media only screen and (max-width: 500px) {
    padding: 8px 11px;
  }
}
body {
  background-image: url("../../assets/images/login-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
