<template>
  <div>
    <header class="text-center">
      <img
        class="mt-2"
        style="width: 200px"
        :src="require('@/assets/images/logos/logo-referencia.svg')"
        alt="Logo"
      />
      <template v-if="payload">
        <h1 class="my-2 containerForm--title">SOLICITUD</h1>
        <h1 class="my-2 containerForm--title">AUTO SERVICIO EXPRESS</h1>
        <p class="infoBranch mb-0">
          <strong>{{ $store.state.branchoffice.nombre.toUpperCase() }}</strong>
        </p>
      </template>
      <template v-else>
        <h1 class="my-2 containerForm--title">QR PREFACTURA COVID</h1>
      </template>

      <p class="d-inline infoBranch mb-8">
        {{ moment().locale("es").format("DD") }}
        de
        {{ moment().locale("es").format("MMMM  YYYY, h:mm:ss a") }}
      </p>
      <slot>
        <p>
          Complete el formulario y haga clic en el botón
          <strong>Confirmar</strong>
        </p>
      </slot>
    </header>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    payload: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      moment: moment,
    };
  },
};
</script>
