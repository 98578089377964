<template>
  <!-- Error page-->
  <div class="">
    <div class="mx-4 mt-5 text-center">
        <img style="width:150px"  :src="require('@/assets/images/logos/logo-referencia.svg')"  alt="Logo" />
    </div>
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Página no encontrada
        </h2>
        <p class="mb-2">
          Oops! 😖 La URL solicitada no se encontró en este servidor.
        </p>
        <!-- image -->
        <b-img
          fluid
          :src="require('@/assets/images/error.svg')"
          alt="Error page"
        />
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
import { BImg } from 'bootstrap-vue'
export default {
  components: {
    BImg,
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss">
body{
  background: #f8f8f8
}
</style>
