<template>
  <div class="bg-white messageContainer shadow--custom">
    <div class="text-center my-5">
      <img class="mt-4"
        style="width:135px"
        :src="require('@/assets/images/logos/logo-referencia.svg')"
        alt="Logo"
      />
      <div class="my-4">
       <x-circle-icon size="4x" class="text-danger"></x-circle-icon>
      </div>
      <div class="mb-2">
      <h3 class="px-4">{{message}}</h3>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../../../components/Footer.vue";
import { XCircleIcon } from 'vue-feather-icons'
export default {
  props:{
    message: {
      type:String, 
      default: ""
    }
  },
  components: {
    Footer,
    XCircleIcon
  },
};
</script>
