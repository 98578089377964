<template>
  <div class="confirmData">
    <HeaderForm :payload="dataBranchOffice">
      <p class="text-primary mb-0">Confirmación de los datos</p>
    </HeaderForm>
    <section v-if="formData.esMenor">
      <div class="formCovid__title my-1">
        <h2 class="formCovid__title--container">
          Datos Personales del Solicitante
        </h2>
      </div>
      <b-row>
        <b-col cols="12" md="4">
          <p class="p-label mb-0">Documento de Identidad</p>
          <p style="word-break: break-all">
            {{ objectIdent[formData.solicitanteIdentificacionTipo].value }} -
            {{ formData.solicitanteIdentificacionNumero }}
          </p>
        </b-col>
        <b-col cols="12" md="4">
          <p class="p-label mb-0">Nombre y Apellido</p>
          <p style="word-break: break-all">
            {{ formData.solicitanteNombres }}
            {{ formData.solicitanteApellidos }}
          </p>
        </b-col>
        <b-col cols="12" md="4">
          <p class="p-label mb-0">Sexo</p>
          <p>{{ objectSex[formData.solicitanteSexo].value }}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="4">
          <p class="p-label mb-0">Teléfono Móvil</p>
          <p>{{ formData.solicitanteTelefono }}</p>
        </b-col>
        <b-col cols="12" md="4">
          <p class="p-label mb-0">Correo Electrónico</p>
          <p style="word-break: break-all">{{ formData.solicitanteEmail }}</p>
        </b-col>
      </b-row>
    </section>
    <section>
      <div class="formCovid__title my-1">
        <h2 class="formCovid__title--container">
          Datos Personales {{ formData.esMenor ? "del Paciente" : "" }}
        </h2>
      </div>
      <b-row>
        <b-col cols="12" md="4">
          <p class="p-label mb-0">Documento de Identidad</p>
          <p style="word-break: break-all">
            {{
              formData.identificacionNumero == ""
                ? ""
                : `${objectIdent[formData.identificacionTipo].value} - `
            }}
            {{ formData.identificacionNumero }}
          </p>
        </b-col>

        <b-col cols="12" md="4">
          <p class="p-label mb-0">Nacionalidad</p>
          <p style="word-break: break-all">
            {{ formData.nacionalidad }}
          </p>
        </b-col>

        <b-col cols="12" md="4">
          <p class="p-label mb-0">Nombre y Apellido</p>
          <p style="word-break: break-all">
            {{ formData.nombres }}
            {{ formData.apellidos }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="4">
          <p class="p-label mb-0">Fecha de Nacimiento</p>
          <p style="word-break: break-all">
            {{ formData.fechaNacimiento }}
          </p>
        </b-col>

        <b-col cols="12" md="4">
          <p class="p-label mb-0">Sexo</p>
          <p style="word-break: break-all">
            {{ objectSex[formData.sexo].value }}
          </p>
        </b-col>
        <b-col cols="12" md="4">
          <p class="p-label mb-0">Teléfono Móvil</p>
          <p style="word-break: break-all">
            {{ formData.telefonoMovil }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="4">
          <p class="p-label mb-0">Correo Electrónico</p>
          <p style="word-break: break-all">
            {{ formData.email }}
          </p>
        </b-col>
      </b-row>
    </section>

    <section v-if="formData.sucursal">
      <div class="formCovid__title my-2">
        <h2 class="formCovid__title--container">Sucursal</h2>
      </div>
      <b-row>
        <b-col cols="12">
          <template v-if="formData.sucursal">
            <BranchofficeBox
              class="my-3"
              :branchoffice="formData.sucursal"
              :showName="true"
            />
          </template>
        </b-col>
      </b-row>
    </section>
    <section>
      <div class="formCovid__title my-2">
        <h2 class="formCovid__title--container">Pruebas a Realizarse</h2>
      </div>
      <b-row>
        <b-col cols="12">
          <ul class="pl-2">
            <li v-for="x in formData.pruebas" :key="x.pruebaid">
              <span style="fs-3"
                >{{ x.nombre }} (RD${{ x.precio | currency }})</span
              >
            </li>
          </ul>
          <p style="font-size: 1.1rem" class="amount">
            Monto total de pruebas a realizarse: RD${{
              formData.monto | currency
            }}
          </p>
        </b-col>
      </b-row>
    </section>
    <section>
      <div class="formCovid__title my-2">
        <h2 class="formCovid__title--container">Datos de Pagos</h2>
      </div>
      <b-row>
        <b-col cols="12">
          <p class="p-label mb-0">Monto</p>
          <p style="word-break: break-all">
            RD${{ formData.monto | currency }}
          </p>
          <p class="p-label mb-0">Nombre del Tarjetahabiente</p>
          <p style="word-break: break-all">
            {{ dataCreditCard.cardName }}
          </p>
          <p class="p-label mb-0">Número de la Tarjeta</p>
          <p style="word-break: break-all">
            {{ dataCreditCard.cardNumber.slice(0, 4) }} ***********
            {{ dataCreditCard.cardNumber.slice(-4) }}
          </p>
          <p class="p-label mb-0">Fecha de Vencimiento</p>
          <p style="word-break: break-all">
            {{ dataCreditCard.cardMonth }}/{{ dataCreditCard.cardYear }}
          </p>
          <p class="p-label mb-0">CVV</p>
          <p style="word-break: break-all">
            ** {{ dataCreditCard.cardCvv.slice(0, 2) }}
          </p>
        </b-col>
      </b-row>
    </section>

    <section>
      <b-row class="mb-3">
        <b-col cols="12">
          <p style="font-size: 1.2rem">Confirmación *</p>
          <b-form-checkbox v-model="checkConfirmation">
            Confirmo que he leído y verificado toda la información
            digitada</b-form-checkbox
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" class="my-1">
          <b-button
            @click="sendData"
            :disabled="!checkConfirmation"
            class="w-100"
            variant="primary"
          >
            Enviar y Pagar
          </b-button>
        </b-col>
        <b-col cols="12" md="6" class="my-1">
          <b-button @click="cancel" class="w-100"> Cancelar </b-button>
        </b-col>
      </b-row>
    </section>
  </div>
</template>
<script>
import { OBJ_SEXOS, OBJ_TIPO_IDENTIFICACION } from "@/utils/constants";
import moment from "moment";
import { BRow, BCol, BFormCheckbox, BButton } from "bootstrap-vue";
import HeaderForm from "./HeaderForm.vue";
import BranchofficeBox from "./BranchofficeBox.vue";

export default {
  props: {
    dataComplete: {
      type: Object,
    },
    dataHeader: {
      type: Object,
    },
  },
  components: {
    BranchofficeBox,
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
    HeaderForm,
  },
  data() {
    return {
      checkConfirmation: false,
      moment: moment,
      formData: {
        solicitanteIdentificacionNumero: "",
        solicitanteIdentificacionTipo: "C",
        solicitanteNombres: "",
        solicitanteApellidos: "",
        solicitanteTelefono: "",
        solicitanteEmail: "",
        solicitanteSexo: "",
        parentesco: "",
        identificacionTipo: "C",
        identificacionNumero: "",
        nacionalidad: "",
        nombres: "",
        apellidos: "",
        fechaNacimiento: "",
        sexo: "",
        telefonoResidencia: "",
        telefonoMovil: "",
        email: "",
        esEmbarazada: false,
        esMenor: false,
        pagoInfo: "",
        monto: 0,
        pruebas: [],
      },
      dataBranchOffice: {},
      dataCreditCard: {
        cardName: "",
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCvv: "",
      },
      objectSex: OBJ_SEXOS,
      objectIdent: OBJ_TIPO_IDENTIFICACION,
      enableButton: true,
    };
  },
  created() {
    this.dataCreditCard = this.dataComplete.dataCard;
    this.formData = this.dataComplete.dataForm;
    this.dataBranchOffice = this.dataHeader;
  },

  methods: {
    sendData() {
      if (this.checkConfirmation && this.enableButton) {
        this.$bvModal
          .msgBoxConfirm(`¿Seguro que desea enviar?`, {
            title: "Confirmación",
            centered: true,
            okTitle: "Si",
            cancelTitle: "No",
          })
          .then(async (value) => {
            if (value) {
              this.$loading.show();
              this.enableButton = false;
              this.$store
                .dispatch({
                  type: "processMeeting",
                  data: {
                    pagoForma: "T",
                    pagoInfo: this.formData.pagoInfo,
                    monto: this.formData.monto,
                    montoTarjetaCredito: this.formData.monto,
                    sucursalId: this.getBranchofficeId(),
                    pacientes: [
                      {
                        ...this.formData,
                        pruebas: this.formData.pruebas.map((x) => {
                          return { pruebaId: x.id, name: x.nombre };
                        }),
                      },
                    ],
                  },
                })
                .then((resp) => {
                  this.$loading.hide();
                  resp.sucursal = this.formData.sucursal
                  this.$emit("handleSubmitData", resp);
                })
                .catch((e) => {
                  this.enableButton = true;
                  this.$loading.hide();
                  this.$swal({
                    title: "Error",
                    text: e.message,
                    icon: "error",
                    customClass: {
                      confirmButton: "btn btn-danger",
                    },
                    buttonsStyling: false,
                  });
                });
            }
          });
      }
    },
    getBranchofficeId(){
      return this.dataBranchOffice ?  this.dataBranchOffice.s.id : this.formData.sucursal.id
    }, 
    cancel() {
      this.$emit("handleCancel");
    },
  },
};
</script>
<style scoped lang="scss">
.p-label {
  font-size: 1rem;
  font-weight: bold;
}

.confirmData {
  > header {
    color: #5e5873;
  }
}
</style>
