<template>
  <div>
    <validation-observer ref="formCovid">
      <div class="d-flex">
        <b-form class="formCovid w-100">
          <div class="text-center mb-3">
            <p class="mb-1"><strong>¿Es menor de edad?</strong></p>
            <b-form-radio-group
              v-model="formData.esMenor"
              class="d-flex justify-content-center"
              name="esMenor"
            >
              <b-form-radio class="radio--age" :value="true">Si</b-form-radio>
              <b-form-radio class="radio--age" :value="false">No</b-form-radio>
            </b-form-radio-group>
          </div>
          <!--  -->
          <!-- Start Datos personales del  colicitante -->
          <!--  -->
          <template v-if="formData.esMenor">
            <div class="formCovid__title my-1">
              <h2 class="formCovid__title--container">
                Datos Personales del Solicitante
              </h2>
            </div>
            <b-row class="mt-3">
              <b-col cols="12" md="4" class="mt-3">
                <b-form-group class="w-100" label="Tipo de Documento *">
                  <validation-provider
                    immediate
                    #default="{ errors }"
                    rules="required"
                    name="Tipo de documento del solicitante"
                    vid="solicitanteIdentificacionTipo"
                  >
                    <v-select
                      v-model="formData.solicitanteIdentificacionTipo"
                      :clearable="false"
                      label="value"
                      :options="listTypeIdentity"
                      :reduce="(option) => option.key"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="4" class="mt-3">
                <b-form-group class="w-100" label="Documento *">
                  <validation-provider
                    immediate
                    #default="{ errors }"
                    vid="solicitanteIdentificacionNumero"
                    :rules="`required|notSayPassport|${
                      formData.solicitanteIdentificacionTipo == 'C'
                        ? 'document'
                        : ''
                    }`"
                    name="El documento del solicitante"
                  >
                    <b-input-group>
                      <b-form-input
                        v-model="formData.solicitanteIdentificacionNumero"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Documento"
                      />
                      <b-input-group-append>
                        <b-button
                          class="py-2 rbtn-group"
                          :disabled="
                            formData.solicitanteIdentificacionTipo != 'C' &&
                            !loadingButtom
                          "
                          @click="
                            getInfoPadron(
                              formData.esMenor,
                              formData.solicitanteIdentificacionTipo,
                              formData.solicitanteIdentificacionNumero
                            )
                          "
                          variant="primary"
                        >
                          <b-spinner
                            small
                            v-if="loadingButtom"
                            class="mr-2"
                          ></b-spinner>
                          <span>Buscar</span>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="4" class="mt-3">
                <validation-provider
                  immediate
                  #default="{ errors }"
                  rules="required"
                  vid="solicitanteNombres"
                  name="El nombre del solicitante"
                >
                  <b-form-group class="w-100" label="Nombre *">
                    <b-form-input
                      v-model="formData.solicitanteNombres"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Nombre"
                      @input="
                        formData.solicitanteNombres = $event.toUpperCase()
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="4" class="mt-3">
                <validation-provider
                  immediate
                  #default="{ errors }"
                  rules="required"
                  vid="solicitanteApellidos"
                  name="El apellido del solicitante"
                >
                  <b-form-group class="w-100" label="Apellido *">
                    <b-form-input
                      v-model="formData.solicitanteApellidos"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Apellido"
                      @input="
                        formData.solicitanteApellidos = $event.toUpperCase()
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" class="mt-3">
                <div class="d-flex w-100">
                  <b-form-group class="w-100" label="Sexo *">
                    <validation-provider
                      immediate
                      #default="{ errors }"
                      rules="required"
                      vid="solicitanteSexo"
                      name="El sexo del solicitante"
                    >
                      <v-select
                        v-model="formData.solicitanteSexo"
                        :clearable="false"
                        label="value"
                        :options="listSex"
                        :reduce="(option) => option.key"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </b-col>
              <b-col cols="12" md="4" class="mt-3">
                <validation-provider
                  immediate
                  #default="{ errors }"
                  rules="required|numeric"
                  vid="solicitanteTelefono"
                  name="El teléfono móvil del solicitante"
                >
                  <b-form-group class="w-100" label="Teléfono Móvil *">
                    <b-form-input
                      v-model="formData.solicitanteTelefono"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Teléfono Móvil"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="4" class="mt-3">
                <validation-provider
                  immediate
                  #default="{ errors }"
                  rules="required|email"
                  name="El correo electrónico del solicitante"
                  vid="solicitanteEmail"
                >
                  <b-form-group class="w-100" label="Correo Electrónico *">
                    <b-form-input
                      v-model="formData.solicitanteEmail"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Correo electrónico"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <!--  -->
            <!-- Start Datos personales del paciente  -->
            <!--  -->
          </template>
          <div class="formCovid__title my-2">
            <h2 class="formCovid__title--container">
              Datos Personales {{ formData.esMenor ? "del Paciente" : "" }}
            </h2>
          </div>
          <b-row class="mt-3">
            <template v-if="!formData.esMenor">
              <b-col cols="12" md="4" class="mt-3">
                <b-form-group class="w-100" label="Tipo de Documento *">
                  <validation-provider
                    immediate
                    #default="{ errors }"
                    rules="required"
                    name="El tipo de documento"
                    vid="identificacionTipo"
                  >
                    <v-select
                      v-model="formData.identificacionTipo"
                      :clearable="false"
                      label="value"
                      :options="listTypeIdentity"
                      :reduce="(option) => option.key"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="4" class="mt-3">
                <b-form-group class="w-100" label="Documento *">
                  <validation-provider
                    immediate
                    #default="{ errors }"
                    :rules="`required|notSayPassport|${
                      formData.identificacionTipo == 'C' ? 'document' : ''
                    }|documentMax:19`"
                    vid="identificacionNumero"
                    name="El documento"
                  >
                    <b-input-group>
                      <b-form-input
                        v-model="formData.identificacionNumero"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Documento"
                      />
                      <b-input-group-append>
                        <b-button
                          class="py-2 rbtn-group"
                          :disabled="
                            formData.identificacionTipo != 'C' && !loadingButtom
                          "
                          @click="
                            getInfoPadron(
                              formData.esMenor,
                              formData.identificacionTipo,
                              formData.identificacionNumero
                            )
                          "
                          variant="primary"
                        >
                          <b-spinner
                            small
                            v-if="loadingButtom"
                            class="mr-4 pr-2"
                          ></b-spinner>

                          <span>Buscar</span>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </template>
            <template v-else>
              <b-col cols="12" md="4" class="mt-3">
                <div class="d-flex w-100">
                  <b-form-group class="w-100" label="Parentesco">
                    <validation-provider
                      immediate
                      #default="{ errors }"
                      rules="required"
                      vid="parentesco"
                      name="El parentesco"
                    >
                      <v-select
                        v-model="formData.parentesco"
                        :clearable="false"
                        label="value"
                        :options="listRelantionship"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </b-col>
              <b-col cols="12" md="4" class="mt-3">
                <b-form-group class="w-100" label="Pasaporte">
                  <b-form-input
                    v-model="formData.identificacionNumero"
                    placeholder="Pasaporte"
                  />
                </b-form-group>
              </b-col>
            </template>

            <b-col cols="12" md="4" class="mt-3">
              <b-form-group class="w-100" label="Nacionalidad *">
                <validation-provider
                  immediate
                  #default="{ errors }"
                  rules="required"
                  vid="nacionalidad"
                  name="La nacionalidad"
                >
                  <v-select
                    v-model="formData.nacionalidad"
                    :clearable="false"
                    label="value"
                    :options="listNationality"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="4" class="mt-3">
              <validation-provider
                immediate
                #default="{ errors }"
                rules="required"
                vid="nombres"
                name="El nombre"
              >
                <b-form-group class="w-100" label="Nombre *">
                  <b-form-input
                    v-model="formData.nombres"
                    :state="errors.length > 0 ? false : null"
                    @input="formData.nombres = $event.toUpperCase()"
                    placeholder="El nombre"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="4" class="mt-3">
              <validation-provider
                immediate
                #default="{ errors }"
                rules="required"
                vid="apellidos"
                name="El apellido"
              >
                <b-form-group class="w-100" label="Apellido *">
                  <b-form-input
                    v-model="formData.apellidos"
                    :state="errors.length > 0 ? false : null"
                    @input="formData.apellidos = $event.toUpperCase()"
                    placeholder="Apellido"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="4" class="mt-3">
              <validation-provider
                immediate
                #default="{ errors }"
                :rules="`required|${formData.esMenor ? 'isMenor' : 'isAdult'}`"
                vid="fechaNacimiento"
                name="La fecha de nacimiento"
              >
                <b-form-group class="w-100" label="Fecha de Nacimiento *">
                  <flat-pickr
                    v-model="formData.fechaNacimiento"
                    :config="configBirthday"
                    class="form-control flat-picker bg-transparent"
                    placeholder="YYYY-MM-DD"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="4" class="mt-3">
              <div class="d-flex w-100">
                <b-form-group class="w-100" label="Sexo *">
                  <validation-provider
                    immediate
                    #default="{ errors }"
                    rules="required"
                    vid="sexo"
                    name="El sexo"
                  >
                    <v-select
                      v-model="formData.sexo"
                      :clearable="false"
                      label="value"
                      :options="listSex"
                      :reduce="(option) => option.key"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="12" md="4" class="mt-3">
              <validation-provider
                immediate
                #default="{ errors }"
                rules="required|numeric"
                vid="telefonoMovil"
                name="El teléfono móvil"
              >
                <b-form-group class="w-100" label="Teléfono Móvil *">
                  <b-form-input
                    v-model="formData.telefonoMovil"
                    placeholder="Teléfono Móvil"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="4" class="mt-3">
              <validation-provider
                immediate
                #default="{ errors }"
                rules="required|email"
                name="El correo electrónico"
                vid="email"
              >
                <b-form-group class="w-100" label="Correo Electrónico *">
                  <b-form-input
                    v-model="formData.email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Correo electrónico"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <div
            class="formCovid__title mb-1 mt-3"
            v-if="listBranchoffice.length > 0"
          >
            <h2 class="formCovid__title--container">Sucursal</h2>
          </div>
          <b-row>
            <template v-if="listBranchoffice.length > 0">
              <b-col cols="12">
                <b-form-group class="w-100 my-3" label="Seleccionar sucursal *">
                  <validation-provider
                    immediate
                    #default="{ errors }"
                    rules="required"
                    name="La sucursal es requerida"
                    vid="sucursalId"
                  >
                    <v-select
                      v-model="formData.sucursal"
                      :clearable="false"
                      label="nombre"
                      :options="listBranchoffice"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <template v-if="formData.sucursal">
                  <BranchofficeBox
                    class="mb-3"
                    :branchoffice="formData.sucursal"
                    :showName="false"
                  />
                </template>
              </b-col>
            </template>
          </b-row>
          <div class="formCovid__title mb-1 mt-3">
            <h2 class="formCovid__title--container">Pruebas a Realizarse</h2>
          </div>
          <b-row class="mt-3">
            <template v-for="x in listTest">
              <b-col cols="12" :key="x.id" class="mb-4">
                <b-form-checkbox v-model="formData.pruebas" :value="x">
                  <span style="font-size: 1.1rem"
                    >{{ x.nombre }} (RD${{ x.precio | currency }})</span
                  >
                </b-form-checkbox>
              </b-col>
            </template>
            <b-col>
              <p style="font-size: 1.1rem" class="amount">
                Monto total de pruebas a realizarse: RD${{
                  amountTest | currency
                }}
              </p>
              <small class="text-danger" v-if="amountTest <= 0"
                >Debe de seleccionar al menos una prueba</small
              >
            </b-col>
          </b-row>
          <div class="formCovid__title my-2">
            <h2 class="formCovid__title--container">Datos de Pagos</h2>
          </div>
          <b-row class="mt-3">
            <b-col>
              <CreditCard
                @handleData="getDataCreditCard"
                :amount="amountTest"
              />
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col cols="12">
              <b-button @click="sendData" class="w-100 py-2" variant="primary">
                <strong style="font-size: 1.05rem"> Confirmar</strong>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </validation-observer>
  </div>
</template>
<script>
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormCheckbox,
  BButton,
  BFormRadio,
  BFormRadioGroup,
  BInputGroupAppend,
  BInputGroup,
  BSpinner,
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import CreditCard from "./creditCard/CreditCard";
import BranchofficeBox from "./BranchofficeBox.vue";
import validateIdentityCard from "@/utils/ValidateIdentity";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { required, email, numeric, max } from "vee-validate/dist/rules";
import {
  LST_SEXOS,
  LST_TIPO_IDENTIFICACION,
  LST_NACIONALIDADES,
  LST_PARENTESCOS_MENORES,
} from "@/utils/constants.js";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

extend("required", {
  ...required,
  message: (field) => `${field} es requerido `,
});
extend("numeric", {
  ...numeric,
  message: (filed) => `${filed} solo debe de contener números`,
});

extend("email", {
  ...email,
  message: (filed) => `${filed} incresado no es valido`,
});
extend("documentMax", {
  ...max,
  message: (filed) => `${filed} máximo de caracteres 19`,
});

extend("document", {
  validate(value) {
    return validateIdentityCard(value);
  },
  message: "Cédula incorrecta",
});

extend("notSayPassport", {
  validate(value){
   let isValid =  ["PASAPORTE", "PASSPORT", "PASA", "PORTE", "SAPORTE", 'PASS']
         .includes(value.toUpperCase())
    return !isValid
  }, 
  message:"El documento es inválido"
})

extend("isAdult", {
  validate(value) {
    const today = (new Date()).toISOString().split('T')[0].replace(/-/g,'');
    const birthdate = (new Date(value)).toISOString().split('T')[0].replace(/-/g,'');
    const age = Math.floor((today - birthdate) / 10000,0);
    return age >= 18;
  },
  message: "Debe ser mayor o igual a 18 años",
});

extend("isMenor", {
  validate(value) {
    const today = (new Date()).toISOString().split('T')[0].replace(/-/g,'');
    const birthdate = (new Date(value)).toISOString().split('T')[0].replace(/-/g,'');
    const age = Math.floor((today - birthdate) / 10000,0);

    return age < 18;
  },
  message: "Debe ser menor de 18 años",
});

export default {
  props: {
    dataOrder: {
      type: Object,
      default: null,
    },
    clean: {
      type: Date,
    },
  },
  components: {
    BranchofficeBox,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    vSelect,
    BRow,
    BCol,
    flatPickr,
    BFormCheckbox,
    CreditCard,
    BButton,
    BFormRadio,
    BFormRadioGroup,
    BInputGroupAppend,
    BInputGroup,
    BSpinner,
  },
  data() {
    return {
      listSex: LST_SEXOS,
      listTypeIdentity: LST_TIPO_IDENTIFICACION,
      listNationality: LST_NACIONALIDADES,
      listRelantionship: LST_PARENTESCOS_MENORES,
      listTest: [],
      loadingButtom: false,
      formData: {
        solicitanteIdentificacionNumero: "",
        solicitanteIdentificacionTipo: "",
        solicitanteNombres: "",
        solicitanteApellidos: "",
        solicitanteTelefono: "",
        solicitanteEmail: "",
        solicitanteSexo: "",
        parentesco: "",
        identificacionTipo: "",
        identificacionNumero: "",
        nacionalidad: "",
        nombres: "",
        apellidos: "",
        fechaNacimiento: "",
        sexo: "",
        TelefonoResidencia: "",
        telefonoMovil: "",
        email: "",
        esEmbarazada: false,
        esMenor: false,
        pagoInfo: "",
        monto: 0,
        pruebas: [],
        sucursal: null,
      },

      dataCreditCard: {
        cardName: "",
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCvv: "",
      },
      configBirthday: {
        maxDate: new Date(),
        minDate: new Date(
          new Date() - 1000 * 60 * 60 * 24 * 51100
        ).toLocaleDateString(),
        locale: Spanish,
        disableMobile: true,
      },
    };
  },
  created() {
    if (this.dataOrder) {
      this.getInfoSucursal();
    } else {
      this.getListBranchoffice();
    }
  },

  methods: {
    getInfoSucursal() {
      this.$loading.show();
      this.$store
        .dispatch({
          type: "getInfoSucursales",
          data: { codigo: this.dataOrder.s.id },
        })
        .then((resp) => {
          this.$loading.hide();
          this.$store.commit("SET_BRANCH_OFFICE", resp);
          this.listTest = resp.pruebas;
        })
        .catch(() => {
          this.$loading.hide();
        });
    },
    getListBranchoffice() {
      this.$loading.show();
      this.$store
        .dispatch({ type: "getSucursales" })
        .then(() => {
          this.$loading.hide();
        })
        .catch(() => {
          this.$loading.hide();
        });
    },
    getInfoPadron(isMinor, identityType, identityNumber) {
      if (
        identityType == "C" &&
        !this.loadingButtom &&
        identityNumber.trim() != ""
      ) {
        this.loadingButtom = true;
        this.$store
          .dispatch({
            type: "getInfoPadron",
            data: identityNumber,
          })
          .then((resp) => {
            if (isMinor) {
              if (resp.nombreCompleto.trim() !== "") {
                this.formData.solicitanteNombres = resp.nombres;
                this.formData.solicitanteApellidos = resp.apellidos;
                this.formData.solicitanteSexo = resp.sexo;
              }
            } else {
              if (resp.nombreCompleto.trim() !== "") {
                this.formData.nombres = resp.nombres;
                this.formData.apellidos = resp.apellidos;
                this.formData.fechaNacimiento = resp.fechaNacimiento;
                this.formData.sexo = resp.sexo;
                this.formData.nacionalidad = "DOMINICANA";
              }
            }
            this.loadingButtom = false;
          });
      }
    },
    async sendData() {
      const isValid = await this.$refs.formCovid.validate();

      const isValidCredicar = !Object.values(this.dataCreditCard).some(
        (x) => x == ""
      );
      if (!isValid) {
        let listErrors = Object.values(this.$refs.formCovid.errors).filter(
          (x) => {
            return x.length > 0;
          }
        );

        let ulErrors = [];
        ulErrors.push(`<ul style="text-align:left">`);
        listErrors.forEach((el) => {
          ulErrors.push(`<li class="text-danger">${el[0]}</li>`);
        });
        ulErrors.push("</ul>");

        this.$swal({
          title: "Por favor verificar ",
          html: `${ulErrors.join("")}`,
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        return;
      }

      if (this.amountTest == 0) {
        this.$swal({
          title: "Error",
          text: "Debe de seleccionar al menos una prueba ",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        return;
      }

      if (!isValidCredicar) {
        this.$swal({
          title: "Error",
          text: "Por favor verificar que todos los campos correspondiente su tarjeta estén completos",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });

        return;
      }

      if (!this.formData.esMenor) {
        this.setDataSolicitante();
      }
      this.formData.pagoInfo = this.getEncryptDataCredit(this.dataCreditCard);

      this.$emit("handleData", {
        dataForm: {
          ...this.formData,
          monto: this.amountTest,
        },
        dataCard: { ...this.dataCreditCard },
      });
    },
    getEncryptDataCredit(data) {
      var text = `${data.cardName}|${data.cardNumber}|${data.cardMonth}/${data.cardYear}|${data.cardCvv}`;
      const base64 = btoa(text);
      return base64;
    },
    handleSelect(value) {
      this.formData.sector = value;
    },
    autocompleteUser($event) {
      if ($event.target.value.trim() == "") {
        this.formData.direccionReferencia = "";
      }
    },
    getDataCreditCard(data) {
      this.dataCreditCard = data;
    },
    setDataSolicitante() {
      this.formData.solicitanteIdentificacionNumero =
        this.formData.identificacionNumero;
      this.formData.solicitanteIdentificacionTipo =
        this.formData.identificacionTipo;
      this.formData.solicitanteNombres = this.formData.nombres;
      this.formData.solicitanteApellidos = this.formData.apellidos;
      this.formData.solicitanteTelefono = this.formData.telefonoMovil;
      this.formData.solicitanteEmail = this.formData.email;
      this.formData.solicitanteSexo = this.formData.sexo;
    },
  },
  computed: {
    amountTest() {
      return this.formData.pruebas.length == 0
        ? 0
        : this.formData.pruebas
            .map((x) => x.precio)
            .reduce((pre, curr) => pre + curr);
    },
    listBranchoffice() {
      return this.$store.state.listBranchoffice;
    },
  },
  watch: {
    "formData.sexo": {
      handler() {
        if (this.formData.sexo == "M" && this.formData.esEmbarazada) {
          this.formData.esEmbarazada = false;
        }
      },
    },
    "formData.sucursal": {
      handler() {
        if (this.formData.sucursal) {
          this.listTest = this.formData.sucursal.pruebas;
          this.formData.pruebas = [];
        }
      },
    },
    "formData.esMenor": {
      handler() {
        this.formData.solicitanteIdentificacionNumero = "";
        this.formData.solicitanteIdentificacionTipo = "";
        this.formData.solicitanteNombres = "";
        this.formData.solicitanteApellidos = "";
        this.formData.solicitanteTelefono = "";
        this.formData.solicitanteEmail = "";
        this.formData.solicitanteSexo = "";
        this.formData.parentesco = "";
        if (this.formData.esMenor) {
          this.formData.identificacionTipo = "P";
        }
      },
    },
    watch: {
      clean() {
        this.formData = {
          solicitanteIdentificacionNumero: "",
          solicitanteIdentificacionTipo: "",
          solicitanteNombres: "",
          solicitanteApellidos: "",
          solicitanteTelefono: "",
          solicitanteEmail: "",
          solicitanteSexo: "",
          parentesco: "",
          identificacionTipo: "",
          identificacionNumero: "",
          nacionalidad: "",
          nombres: "",
          apellidos: "",
          fechaNacimiento: "",
          sexo: "",
          TelefonoResidencia: "",
          telefonoMovil: "",
          email: "",
          esEmbarazada: false,
          esMenor: false,
          pagoInfo: "",
          monto: 0,
          pruebas: [],
        };

        this.dataCreditCard = {
          cardName: "",
          cardNumber: "",
          cardMonth: "",
          cardYear: "",
          cardCvv: "",
        };
      },
    },
  },
};
</script>

<style lang="scss">
.rbtn-group {
  border-radius: 0px 5px 5px 0px;
}
.formCovid {
  &__title {
    text-align: center;
    background: #000;
    padding: 7px;
    &--container {
      font-size: 1rem;
      margin: 0;
      color: #fff;
    }
  }

  > div:first-child {
    color: #6e6b7b;
  }
  .radio--age {
    font-size: 1.2rem;
  }
}
</style>
